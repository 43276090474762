<template>
  <v-card flat>
    <v-card-title>
      Sport button templates
    </v-card-title>
    <v-card-text>
      <v-btn
        @click="$router.push(`/create-sport-template/${$route.params.id}`)"
        fab
        color="primary"
        large
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <div
        style="cursor: pointer;"
        @click="$router.push(`/edit-button-template/${template.id}/sport/${$route.params.id}`)"
        v-for="template in templates" :key="template.id">
        <v-row>
          <v-col cols="6">
            <v-card-title>
              {{template.template_name}}
            </v-card-title>
          </v-col>
          <v-col>
            Items: {{template.items.length}}
          </v-col>
          <v-col>
            Rules: {{template.rules.length}}
          </v-col>
          <v-col>
            Prequestions: {{template.form_items.length}}
          </v-col>
          <v-col>
            <v-btn fab x-small class="mt-3" @click.stop="deletingTemplate = template.id">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
    </v-card-text>
    <confirmation-dialog
      :show="deletingTemplate"
      @decline="deletingTemplate = null"
      @accept="handleDelete()"
      btnText="Delete"
      text="Ei voi perua"
      subText="Ei voi perua"
      btnColor="red"
    ></confirmation-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmationDialog from '../../ConfirmationDialog.vue'
export default {
  components: { ConfirmationDialog },
  created() {
    this.initSportTemplates(this.$route.params.id)
  },
  data: () => ({
    deletingTemplate: null
  }),
  methods: {
    ...mapActions('template', [
      'initSportTemplates',
      'deleteTemplate'
    ]),
    ...mapActions('noti', [
      'error',
      'info'
    ]),
    startDeletingTemplate() {
      this.showConfDialog = true
    },
    handleDelete() {
      this.deleteTemplate(this.deletingTemplate)
        .then(() => {
          this.info('deleted')
        }) 
        .catch(e => {
          this.error(e)
        })
      this.deletingTemplate = null;
    }
  },
  computed: {
    ...mapGetters('template', [
      'templates'
    ])
  }
}
</script>